import React, {useEffect, useRef} from "react"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import Banner from "../../../components/banner"

const Contenido = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="Market Entry Program"/>
            <Banner title="Market Entry Program"/>
            <section className="service_details_area aspectos servicios">
                <div className="container">
                    <div className="section_title black_c">
                        <p className="f_16">This solutions package is aimed at private or family enterprises that are
                            considering their entry into the debt markets, but there are numerous factors yet to be
                            defined, such as the type of instrument, amount, offering date, among others. IRStrat’s
                            solution focuses on guiding the client in the right direction, recommend the best options of
                            instruments to issue, shortening the go-to-market time and easing the execution of the
                            placement. This package includes:</p>
                    </div>
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="service_left_sidebar">
                                <ul className="nav nav-tabs service_tab mb_50" id="myTab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active show" id="paquete1-tab" data-toggle="tab"
                                           href="#paquete1" role="tab"
                                           aria-controls="paquete1" aria-selected="true">Package 1</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="paquete2-tab" data-toggle="tab" href="#paquete2"
                                           role="tab"
                                           aria-controls="paquete2" aria-selected="false">Package 2</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="paquete3-tab" data-toggle="tab" href="#paquete3"
                                           role="tab"
                                           aria-controls="paquete3" aria-selected="false">Package 3</a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade active show" id="paquete1" role="tabpanel"
                                     aria-labelledby="paquete1-tab">
                                    <div className="service_right_sidebar p-0 mb-5">
                                        <div className="service_content">
                                            <div id="accordion" role="tablist" aria-multiselectable="true"
                                                 className="panel-group faq-accordion service_accordion">
                                                <div className="card active">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a data-toggle="collapse" data-parent="#accordion"
                                                               href="#collapse1"
                                                               className="btn-accordion"
                                                               aria-expanded="true" role="button"
                                                               aria-controls="collapse1">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Strategic plan
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapse1" className="collapse show" aria-expanded="true"
                                                         role="tabpanel">
                                                        <div className="card-block panel-body">


                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Initial study
                                                                    on issuance capacities
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Development of
                                                                    capacity assessment grid
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Definition of
                                                                    the go-to-market calendar
                                                                </li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#accordion" href="#collapse2"
                                                               className="btn-accordion collapsed" aria-expanded="false"
                                                               aria-controls="collapse2">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Message core
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapse2" className="panel-collapse collapse"
                                                         aria-expanded="false" role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Investment
                                                                    thesis development
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Shaping of
                                                                    competitive advantage narrative
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Crafting of the
                                                                    history narrative
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#accordion" href="#collapse3"
                                                               className="btn-accordion collapsed"
                                                               aria-expanded="false">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Structure and roles
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapse3" className="panel-collapse collapse"
                                                         aria-expanded="false" role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Establishment
                                                                    of the IR department
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Definition of
                                                                    reporting functions
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Definition of
                                                                    IR functions
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Strategic
                                                                    recruitment
                                                                </li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="paquete2" role="tabpanel"
                                     aria-labelledby="paquete2-tab">
                                    <div className="service_right_sidebar p-0 mb-5">
                                        <div className="service_content">
                                            <div id="p2_accordion" role="tablist" aria-multiselectable="true"
                                                 className="panel-group faq-accordion service_accordion">
                                                <div className="card active">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a data-toggle="collapse" data-parent="#p2_accordion"
                                                               href="#p2_collapse1"
                                                               className="btn-accordion"
                                                               aria-expanded="true" role="button"
                                                               aria-controls="p2_collapse1">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Team training
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="p2_collapse1" className="collapse show"
                                                         aria-expanded="true" role="tabpanel">
                                                        <div className="card-block panel-body">


                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Training in
                                                                    corporate finance
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Training in
                                                                    financial communication
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Training in
                                                                    financial regulatory framework
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Mapping of
                                                                    reporting processes
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#p2_accordion" href="#p2_collapse2"
                                                               className="btn-accordion collapsed" aria-expanded="false"
                                                               aria-controls="p2_collapse2">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Regulatory compliance
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="p2_collapse2" className="panel-collapse collapse"
                                                         aria-expanded="false"
                                                         role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Consultancy on
                                                                    collection of financial information
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Consultancy on
                                                                    financial processing and interpretation
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Maximum
                                                                    compliance with CNBV
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Maximum
                                                                    compliance with BMV
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Maximum
                                                                    compliance with BIVA
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Preliminary
                                                                    bases for SEC compliance
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#p2_accordion" href="#p2_collapse3"
                                                               className="btn-accordion collapsed"
                                                               aria-expanded="false">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Development of materials</a>
                                                        </h4>
                                                    </div>
                                                    <div id="p2_collapse3" className="panel-collapse collapse"
                                                         aria-expanded="false"
                                                         role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Quarterly
                                                                    report
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Annual report
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Corporate
                                                                    presentation
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>IR website
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="paquete3" role="tabpanel"
                                     aria-labelledby="paquete3-tab">
                                    <div className="service_right_sidebar p-0 mb-5">
                                        <div className="service_content">
                                            <div id="p3_accordion" role="tablist" aria-multiselectable="true"
                                                 className="panel-group faq-accordion service_accordion">
                                                <div className="card active">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a data-toggle="collapse" data-parent="#p3_accordion"
                                                               href="#p3_collapse1"
                                                               className="btn-accordion"
                                                               aria-expanded="true" role="button"
                                                               aria-controls="p3_collapse1">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Best practices</a>
                                                        </h4>
                                                    </div>
                                                    <div id="p3_collapse1" className="collapse show"
                                                         aria-expanded="true" role="tabpanel">
                                                        <div className="card-block panel-body">


                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Consultancy on
                                                                    corporate governance
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Consultancy on
                                                                    maximum reporting quality
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Consultancy on
                                                                    reporting accuracy
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#p3_accordion" href="#p3_collapse2"
                                                               className="btn-accordion collapsed" aria-expanded="false"
                                                               aria-controls="p3_collapse2">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Strategic agenda</a>
                                                        </h4>
                                                    </div>
                                                    <div id="p3_collapse2" className="panel-collapse collapse"
                                                         aria-expanded="false"
                                                         role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Approaching to
                                                                    underwriters
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Approaching to
                                                                    institutional investors
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Approaching to
                                                                    credit rating agencies
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Approaching to
                                                                    specialized financial media
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#p3_accordion" href="#p3_collapse3"
                                                               className="btn-accordion collapsed"
                                                               aria-expanded="false">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Benchmarking</a>
                                                        </h4>
                                                    </div>
                                                    <div id="p3_collapse3" className="panel-collapse collapse"
                                                         aria-expanded="false"
                                                         role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Identification
                                                                    of industry and peers
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Comparative
                                                                    valuations
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Comparative
                                                                    results
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Comparative
                                                                    go-to-market
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </Layout>
    )
}

export default Contenido
